<template>
  <v-row no-gutters style="height: 150px;">
    <v-col></v-col>
    <v-col align-self="center">

      <v-row>
        <v-card class="mx-auto" max-width="500" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="headline mb-1">
                Session Expired
              </v-list-item-title>
              The security of your information is of critical importance.
              Your session has expired and has been logged off.
              You can log in again here:
            </v-list-item-content>
          </v-list-item>

          <v-card-actions>
            <v-btn @click="login" color="primary">
              Login
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-row>

      <v-row align="center">
        <v-col align="center" class="py-8">
          Thank you for using Simple Time Cards!
        </v-col>
      </v-row>
    </v-col>
    <v-col></v-col>
  </v-row>
</template>

<script>
  import { SHOW_LOGIN } from '../store/store_constants'

  export default {
    name: "Logout",

    methods: {
      login () {
        console.log('Show login screen.')
        this.$store.commit(SHOW_LOGIN, true)
      }
    },
    data: () => ({
      alignments: [
        'start',
        'center',
        'end'
      ]
    })

  }
</script>

<style scoped>

</style>
